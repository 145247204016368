import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction`}</h2>
    <p>{`The IBM ECDN (Enterprise Content Delivery Network) API lets you monitor and manage your ECDN locations, servers and other resources.`}</p>
    <h2>{`Try it out`}</h2>
    <p><a parentName="p" {...{
        "href": "https://ecdn.video.ibm.com/graphiql"
      }}>{`API Browser`}</a>{` is the easiest way to try ECDN’s GraphQL API.
You can log in via your ECDN credentials.
Generated documentation can be found by opening `}<inlineCode parentName="p">{`Docs`}</inlineCode>{` in the upper-right corner, which are also available on this site.`}</p>
    <h2>{`GraphQL`}</h2>
    <p>{`We suggest reading the `}<a parentName="p" {...{
        "href": "https://graphql.org/learn/"
      }}>{`official GraphQL documentation`}</a>{`
to get a basic understanding of its usage. This documentation focuses on ECDN specific usage.`}</p>
    <h3>{`All/specific resources`}</h3>
    <p>{`We will use `}<inlineCode parentName="p">{`servers`}</inlineCode>{` and `}<inlineCode parentName="p">{`locations`}</inlineCode>{` throughout this document, which covers all servers and/or all locations when used.
If you want to query them one-by-one use `}<inlineCode parentName="p">{`serverByID`}</inlineCode>{` and `}<inlineCode parentName="p">{`locationByID`}</inlineCode>{` instead. i.e:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
    locations {
        id
        name
    }
}
`}</code></pre>
    <p>{`will return ID and name of all locations, where`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
    locationByID(id:"bG9jYXRpb246MzA1") {
        id
        name
    }
}
`}</code></pre>
    <p>{`will return ID and name of location with ID of `}<inlineCode parentName="p">{`"bG9jYXRpb246MzA1"`}</inlineCode>{` only (if it exists).`}</p>
    <h3>{`Example queries`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Locations ID, name, external ranges and number of servers.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-graphql"
          }}>{`{
    locations {
        id
        name
        numberOfServers
        externalRanges
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Servers ID, FQDN, CPU, memory, system disk size (in bytes), cache disk size (in bytes), IP address,
location name, ECDN version with patch level and last check in date.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-graphql"
          }}>{`{
    servers {
        id
        fqdn
        lastCheckIn
        location {
            name
        }
        update {
            ecdnVersion
            patchLevel
        }
        hardware {
            cpuCores
            memory
            systemDiskSize
            cacheDiskSize
        }
        desiredConfiguration {
            interfaces{
                localIP
            }
        }
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Servers ID, FQDN, detailed health and status of video delivery.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-graphql"
          }}>{`{
    servers {
        id
        fqdn
        health {
            contentDelivery {
                aggregated
                upstreams
                caching
            }
            operations {
                aggregated
                updates
                softwareVersion
                apiConnection
                metrics
                ntp
                logForward
                remoteAssistance
                snmp
            }
        }
        videoDelivery {
            currentNumberOfViewers
            currentNumberOfBuffering
        }
    }
}
`}</code></pre>
      </li>
    </ul>
    <h2>{`Basic workflow`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Fetch tokens.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`curl 'https://api.ecdn.video.ibm.com/v1/login' \\
    -d '{"email":"...","password":"..."}'
`}</code></pre>
        <p parentName="li">{`Returns:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    # JWT token, see https://jwt.io.
    "accessToken": "YOUR_ACCESS_TOKEN",
    # This can only be used once.
    "refreshToken": "YOUR_REFRESH_TOKEN",
    # Currently, refresh token expires in one day.
    "refreshAgeSeconds": 86400
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use API with access token. Note that all if you had a query `}<inlineCode parentName="p">{`{servers{id}}`}</inlineCode>{` at API Browser,
you must send it as `}<inlineCode parentName="p">{`'{"query":"{servers{id}}"}'`}</inlineCode>{` if you use the API directly.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`curl -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \\
    https://api.ecdn.video.ibm.com/v1/graphql \\
    -d '{"query":"{servers{id}}"}'
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Refresh tokens. Currently your access token expires in 5 minutes.
The actual expiration time can be extracted from `}<a parentName="p" {...{
            "href": "https://jwt.io"
          }}>{`JWT`}</a>{`’s `}<inlineCode parentName="p">{`exp`}</inlineCode>{` field.
Therefore, you must use your refresh token to request a new access token before/if it expires.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`curl 'https://api.ecdn.video.ibm.com/v1/token/refresh' \\
    -d '{"refreshToken":"YOUR_REFRESH_TOKEN"}'
`}</code></pre>
        <p parentName="li">{`Returns:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
    # JWT token, see https://jwt.io.
    "accessToken": "YOUR_NEW_ACCESS_TOKEN",
    # This can only be used once.
    "refreshToken": "YOUR_NEW_REFRESH_TOKEN",
    # Currently, refresh token expires in one day.
    "refreshAgeSeconds": 86400
}
`}</code></pre>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      